import shop1 from "./images/shop_1.png";
import shop2 from "./images/shop_2.png";
import shop3 from "./images/shop_3.png";
import cat1 from "./images/cat1.png";
import cat2 from "./images/cat_2.png";
import cat3 from "./images/cat_3.png";

export const slideItems = [
  {
    id: "1",
    img: shop2,
    title: "Pottery Bowls",
    desc: "Maybe it's time you consider replacing your dishes...",
  },
  {
    id: "2",
    img: shop1,
    title: "Pots",
    desc: "... and getting rid of the toxic ingredients inside your plate.",
  },
  {
    id: "3",
    img: shop3,
    title: "Souvenirs",
    desc: "C'mon let's choose today your favorite dish...",
  },
];

export const categories = [
  {
    id: 1,
    img: cat1,
    title: "Pottery Bowls",
    cat: "bowls",
  },
  {
    id: 2,
    img: cat2,
    title: "Pottery Pots",
    cat: "pots",
  },
  {
    id: 3,
    img: cat3,
    title: "Pottery Souvenirs",
    cat: "souvenirs",
  },
];
export const productItems = [
  {
    id: 1,
    img: "https://i.ibb.co/LtG4w9j/shop2.png",
    title: "Peke",
    price: "30",
  },
  {
    id: 2,
    img: "https://i.ibb.co/LtG4w9j/shop2.png",
    title: "Bakre",
    price: "20",
  },
  {
    id: 3,
    img: "https://i.ibb.co/LtG4w9j/shop2.png",
    title: "Lonci",
    price: "10",
  },
  {
    id: 4,
    img: "https://i.ibb.co/LtG4w9j/shop2.png",
    title: "Peke",
    price: "30",
  },
  {
    id: 5,
    img: "https://i.ibb.co/LtG4w9j/shop2.png",
    title: "Bakre",
    price: "35",
  },
  {
    id: 6,
    img: "https://i.ibb.co/LtG4w9j/shop2.png",
    title: "Lonci",
    price: "40",
  },
  {
    id: 7,
    img: "https://i.ibb.co/LtG4w9j/shop2.png",
    title: "Peke",
    price: "30",
  },
  {
    id: 8,
    img: "https://i.ibb.co/LtG4w9j/shop2.png",
    title: "Bakre",
    price: "20",
  },
  {
    id: 9,
    img: "https://i.ibb.co/LtG4w9j/shop2.png",
    title: "Lonci",
    price: "10",
  },
  {
    id: 10,
    img: "https://i.ibb.co/LtG4w9j/shop2.png",
    title: "Peke",
    price: "30",
  },
  {
    id: 11,
    img: "https://i.ibb.co/LtG4w9j/shop2.png",
    title: "Bakre",
    price: "35",
  },
  {
    id: 12,
    img: "https://i.ibb.co/LtG4w9j/shop2.png",
    title: "Lonci",
    price: "40",
  },
];

export const countries = [
  { name: "Afghanistan", code: "AF", price: 50 },
  { name: "Åland Islands", code: "AX", price: 50 },
  { name: "Albania", code: "AL", price: 50 },
  { name: "Algeria", code: "DZ", price: 50 },
  { name: "American Samoa", code: "AS", price: 50 },
  { name: "AndorrA", code: "AD", price: 50 },
  { name: "Angola", code: "AO", price: 50 },
  { name: "Anguilla", code: "AI", price: 50 },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG", price: 50 },
  { name: "Argentina", code: "AR", price: 50 },
  { name: "Armenia", code: "AM", price: 50 },
  { name: "Aruba", code: "AW", price: 50 },
  { name: "Australia", code: "AU", price: 50 },
  { name: "Austria", code: "AT", price: 50 },
  { name: "Azerbaijan", code: "AZ", price: 50 },
  { name: "Bahamas", code: "BS", price: 50 },
  { name: "Bahrain", code: "BH", price: 50 },
  { name: "Bangladesh", code: "BD", price: 50 },
  { name: "Barbados", code: "BB", price: 50 },
  { name: "Belarus", code: "BY", price: 50 },
  { name: "Belgium", code: "BE", price: 50 },
  { name: "Belize", code: "BZ", price: 50 },
  { name: "Benin", code: "BJ", price: 50 },
  { name: "Bermuda", code: "BM", price: 50 },
  { name: "Bhutan", code: "BT", price: 50 },
  { name: "Bolivia", code: "BO", price: 50 },
  { name: "Bosnia and Herzegovina", code: "BA", price: 50 },
  { name: "Botswana", code: "BW", price: 50 },
  { name: "Bouvet Island", code: "BV", price: 50 },
  { name: "Brazil", code: "BR", price: 50 },
  { name: "British Indian Ocean Territory", code: "IO", price: 50 },
  { name: "Brunei Darussalam", code: "BN", price: 50 },
  { name: "Bulgaria", code: "BG", price: 50 },
  { name: "Burkina Faso", code: "BF", price: 50 },
  { name: "Burundi", code: "BI", price: 50 },
  { name: "Cambodia", code: "KH", price: 50 },
  { name: "Cameroon", code: "CM", price: 50 },
  { name: "Canada", code: "CA", price: 50 },
  { name: "Cape Verde", code: "CV", price: 50 },
  { name: "Cayman Islands", code: "KY", price: 50 },
  { name: "Central African Republic", code: "CF", price: 50 },
  { name: "Chad", code: "TD", price: 50 },
  { name: "Chile", code: "CL", price: 50 },
  { name: "China", code: "CN", price: 50 },
  { name: "Christmas Island", code: "CX", price: 50 },
  { name: "Cocos (Keeling) Islands", code: "CC", price: 50 },
  { name: "Colombia", code: "CO", price: 50 },
  { name: "Comoros", code: "KM", price: 50 },
  { name: "Congo", code: "CG", price: 50 },
  { name: "Congo, The Democratic Republic of the", code: "CD", price: 50 },
  { name: "Cook Islands", code: "CK", price: 50 },
  { name: "Costa Rica", code: "CR", price: 50 },
  { name: "Cote D'Ivoire", code: "CI", price: 50 },
  { name: "Croatia", code: "HR", price: 50 },
  { name: "Cuba", code: "CU", price: 50 },
  { name: "Cyprus", code: "CY", price: 50 },
  { name: "Czech Republic", code: "CZ", price: 50 },
  { name: "Denmark", code: "DK", price: 50 },
  { name: "Djibouti", code: "DJ", price: 50 },
  { name: "Dominica", code: "DM", price: 50 },
  { name: "Dominican Republic", code: "DO", price: 50 },
  { name: "Ecuador", code: "EC", price: 50 },
  { name: "Egypt", code: "EG", price: 50 },
  { name: "El Salvador", code: "SV", price: 50 },
  { name: "Equatorial Guinea", code: "GQ", price: 50 },
  { name: "Eritrea", code: "ER", price: 50 },
  { name: "Estonia", code: "EE", price: 50 },
  { name: "Ethiopia", code: "ET", price: 50 },
  { name: "Falkland Islands (Malvinas)", code: "FK", price: 50 },
  { name: "Faroe Islands", code: "FO", price: 50 },
  { name: "Fiji", code: "FJ", price: 50 },
  { name: "Finland", code: "FI", price: 50 },
  { name: "France", code: "FR", price: 50 },
  { name: "French Guiana", code: "GF", price: 50 },
  { name: "French Polynesia", code: "PF", price: 50 },
  { name: "French Southern Territories", code: "TF", price: 50 },
  { name: "Gabon", code: "GA", price: 50 },
  { name: "Gambia", code: "GM", price: 50 },
  { name: "Georgia", code: "GE", price: 50 },
  { name: "Germany", code: "DE", price: 50 },
  { name: "Ghana", code: "GH", price: 50 },
  { name: "Gibraltar", code: "GI", price: 50 },
  { name: "Greece", code: "GR", price: 50 },
  { name: "Greenland", code: "GL", price: 50 },
  { name: "Grenada", code: "GD", price: 50 },
  { name: "Guadeloupe", code: "GP", price: 50 },
  { name: "Guam", code: "GU", price: 50 },
  { name: "Guatemala", code: "GT", price: 50 },
  { name: "Guernsey", code: "GG", price: 50 },
  { name: "Guinea", code: "GN", price: 50 },
  { name: "Guinea-Bissau", code: "GW", price: 50 },
  { name: "Guyana", code: "GY", price: 50 },
  { name: "Haiti", code: "HT", price: 50 },
  { name: "Heard Island and Mcdonald Islands", code: "HM", price: 50 },
  { name: "Holy See (Vatican City State)", code: "VA", price: 50 },
  { name: "Honduras", code: "HN", price: 50 },
  { name: "Hong Kong", code: "HK", price: 50 },
  { name: "Hungary", code: "HU", price: 50 },
  { name: "Iceland", code: "IS", price: 50 },
  { name: "India", code: "IN", price: 50 },
  { name: "Indonesia", code: "ID", price: 50 },
  { name: "Iran, Islamic Republic Of", code: "IR", price: 50 },
  { name: "Iraq", code: "IQ", price: 50 },
  { name: "Ireland", code: "IE", price: 50 },
  { name: "Isle of Man", code: "IM", price: 50 },
  { name: "Israel", code: "IL", price: 50 },
  { name: "Italy", code: "IT", price: 50 },
  { name: "Jamaica", code: "JM", price: 50 },
  { name: "Japan", code: "JP", price: 50 },
  { name: "Jersey", code: "JE", price: 50 },
  { name: "Jordan", code: "JO", price: 50 },
  { name: "Kazakhstan", code: "KZ", price: 50 },
  { name: "Kenya", code: "KE", price: 50 },
  { name: "Kiribati", code: "KI", price: 50 },
  { name: "Korea, Democratic People'S Republic of", code: "KP", price: 50 },
  { name: "Korea, Republic of", code: "KR", price: 50 },
  { name: "Kuwait", code: "KW", price: 50 },
  { name: "Kyrgyzstan", code: "KG", price: 50 },
  { name: "Lao People'S Democratic Republic", code: "LA", price: 50 },
  { name: "Latvia", code: "LV", price: 50 },
  { name: "Lebanon", code: "LB", price: 50 },
  { name: "Lesotho", code: "LS", price: 50 },
  { name: "Liberia", code: "LR", price: 50 },
  { name: "Libyan Arab Jamahiriya", code: "LY", price: 50 },
  { name: "Liechtenstein", code: "LI", price: 50 },
  { name: "Lithuania", code: "LT", price: 50 },
  { name: "Luxembourg", code: "LU", price: 50 },
  { name: "Macao", code: "MO", price: 50 },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK", price: 50 },
  { name: "Madagascar", code: "MG", price: 50 },
  { name: "Malawi", code: "MW", price: 50 },
  { name: "Malaysia", code: "MY", price: 50 },
  { name: "Maldives", code: "MV", price: 50 },
  { name: "Mali", code: "ML", price: 50 },
  { name: "Malta", code: "MT", price: 50 },
  { name: "Marshall Islands", code: "MH", price: 50 },
  { name: "Martinique", code: "MQ", price: 50 },
  { name: "Mauritania", code: "MR", price: 50 },
  { name: "Mauritius", code: "MU", price: 50 },
  { name: "Mayotte", code: "YT", price: 50 },
  { name: "Mexico", code: "MX", price: 50 },
  { name: "Micronesia, Federated States of", code: "FM", price: 50 },
  { name: "Moldova, Republic of", code: "MD", price: 50 },
  { name: "Monaco", code: "MC", price: 50 },
  { name: "Mongolia", code: "MN", price: 50 },
  { name: "Montserrat", code: "MS", price: 50 },
  { name: "Morocco", code: "MA", price: 50 },
  { name: "Mozambique", code: "MZ", price: 50 },
  { name: "Myanmar", code: "MM", price: 50 },
  { name: "Namibia", code: "NA", price: 50 },
  { name: "Nauru", code: "NR", price: 50 },
  { name: "Nepal", code: "NP", price: 50 },
  { name: "Netherlands", code: "NL", price: 50 },
  { name: "Netherlands Antilles", code: "AN", price: 50 },
  { name: "New Caledonia", code: "NC", price: 50 },
  { name: "New Zealand", code: "NZ", price: 50 },
  { name: "Nicaragua", code: "NI", price: 50 },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG", price: 50 },
  { name: "Niue", code: "NU", price: 50 },
  { name: "Norfolk Island", code: "NF", price: 50 },
  { name: "Northern Mariana Islands", code: "MP", price: 50 },
  { name: "Norway", code: "NO", price: 50 },
  { name: "Oman", code: "OM", price: 50 },
  { name: "Pakistan", code: "PK", price: 50 },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS", price: 50 },
  { name: "Panama", code: "PA", price: 50 },
  { name: "Papua New Guinea", code: "PG", price: 50 },
  { name: "Paraguay", code: "PY", price: 50 },
  { name: "Peru", code: "PE", price: 50 },
  { name: "Philippines", code: "PH", price: 50 },
  { name: "Pitcairn", code: "PN", price: 50 },
  { name: "Poland", code: "PL", price: 50 },
  { name: "Portugal", code: "PT", price: 50 },
  { name: "Puerto Rico", code: "PR", price: 50 },
  { name: "Qatar", code: "QA", price: 50 },
  { name: "Reunion", code: "RE", price: 50 },
  { name: "Romania", code: "RO", price: 50 },
  { name: "Russian Federation", code: "RU", price: 50 },
  { name: "RWANDA", code: "RW", price: 50 },
  { name: "Saint Helena", code: "SH", price: 50 },
  { name: "Saint Kitts and Nevis", code: "KN", price: 50 },
  { name: "Saint Lucia", code: "LC", price: 50 },
  { name: "Saint Pierre and Miquelon", code: "PM", price: 50 },
  { name: "Saint Vincent and the Grenadines", code: "VC", price: 50 },
  { name: "Samoa", code: "WS", price: 50 },
  { name: "San Marino", code: "SM", price: 50 },
  { name: "Sao Tome and Principe", code: "ST", price: 50 },
  { name: "Saudi Arabia", code: "SA", price: 50 },
  { name: "Senegal", code: "SN", price: 50 },
  { name: "Serbia and Montenegro", code: "CS", price: 50 },
  { name: "Seychelles", code: "SC", price: 50 },
  { name: "Sierra Leone", code: "SL", price: 50 },
  { name: "Singapore", code: "SG", price: 50 },
  { name: "Slovakia", code: "SK", price: 50 },
  { name: "Slovenia", code: "SI", price: 50 },
  { name: "Solomon Islands", code: "SB", price: 50 },
  { name: "Somalia", code: "SO", price: 50 },
  { name: "South Africa", code: "ZA", price: 50 },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    price: 50,
  },
  { name: "Spain", code: "ES", price: 50 },
  { name: "Sri Lanka", code: "LK", price: 50 },
  { name: "Sudan", code: "SD", price: 50 },
  { name: "Suriname", code: "SR", price: 50 },
  { name: "Svalbard and Jan Mayen", code: "SJ", price: 50 },
  { name: "Swaziland", code: "SZ", price: 50 },
  { name: "Sweden", code: "SE", price: 50 },
  { name: "Switzerland", code: "CH", price: 50 },
  { name: "Syrian Arab Republic", code: "SY", price: 50 },
  { name: "Taiwan, Province of China", code: "TW", price: 50 },
  { name: "Tajikistan", code: "TJ", price: 50 },
  { name: "Tanzania, United Republic of", code: "TZ", price: 50 },
  { name: "Thailand", code: "TH", price: 50 },
  { name: "Timor-Leste", code: "TL", price: 50 },
  { name: "Togo", code: "TG", price: 50 },
  { name: "Tokelau", code: "TK", price: 50 },
  { name: "Tonga", code: "TO", price: 50 },
  { name: "Trinidad and Tobago", code: "TT", price: 50 },
  { name: "Tunisia", code: "TN", price: 50 },
  { name: "Turkey", code: "TR", price: 50 },
  { name: "Turkmenistan", code: "TM", price: 50 },
  { name: "Turks and Caicos Islands", code: "TC", price: 50 },
  { name: "Tuvalu", code: "TV", price: 50 },
  { name: "Uganda", code: "UG", price: 50 },
  { name: "Ukraine", code: "UA", price: 50 },
  { name: "United Arab Emirates", code: "AE", price: 50 },
  { name: "United Kingdom", code: "GB", price: 50 },
  { name: "United States", code: "US", price: 50 },
  { name: "United States Minor Outlying Islands", code: "UM", price: 50 },
  { name: "Uruguay", code: "UY", price: 50 },
  { name: "Uzbekistan", code: "UZ", price: 50 },
  { name: "Vanuatu", code: "VU", price: 50 },
  { name: "Venezuela", code: "VE", price: 50 },
  { name: "Viet Nam", code: "VN", price: 50 },
  { name: "Virgin Islands, British", code: "VG", price: 50 },
  { name: "Virgin Islands, U.S.", code: "VI", price: 50 },
  { name: "Wallis and Futuna", code: "WF", price: 50 },
  { name: "Western Sahara", code: "EH", price: 50 },
  { name: "Yemen", code: "YE", price: 50 },
  { name: "Zambia", code: "ZM", price: 50 },
  { name: "Zimbabwe", code: "ZW", price: 50 },
];
